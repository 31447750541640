<template>
  <b-card title="Controllo vincitore">
    <div v-if="finish && user.length > 0">
      <b-alert
        variant="warning"
        :show="!isEnded('01/10/2020','01/23/2020')"
      >
        <h4 class="alert-heading">
          ATTENZIONE IL BUONO NON E' UTILIZZABILE AL MOMENTO, E' VALIDO DAL 10 AL 23 GENNAIO
        </h4>
      </b-alert>
      <b-alert
        variant="warning"
        :show="user[0].date_used != null"
      >
        <h4 class="alert-heading">
          ATTENZIONE IL BUONO E' GIA' STATO UTILIZZATO
        </h4>
      </b-alert>
      <b-card-text>
        <b>Nome e cognome:</b> {{ user[0].name }}
      </b-card-text>
      <b-card-text>
        <b>Email:</b> {{ user[0].email }}
      </b-card-text>
      <b-card-text>
        <b>Vincita:</b> {{ user[0].prize_name }}
      </b-card-text>
      <b-card-text>
        <b>Utilizzabile dal 10 al 23 Gennaio</b>
      </b-card-text>
      <b-card-text>
        <b>Data utilizzo:</b> <span v-html="getDate(user[0].date_used)"></span>
      </b-card-text>
      <b-card-text>
        <b>Codice gratta e vinci:</b> #{{ user[0].order_number }}
      </b-card-text>
      <b-card-text>
        <b>Ristorante di utilizzo:</b> {{ user[0].restaurant }}
      </b-card-text>
      <input v-if="user[0].date_used == null" type="text" style="width:100%; padding: 10px; border: 1px solid rgb(189 189 189); border-radius:10px;" v-model="restaurant" placeholder="Ristorante dove si sta utilizzando il buono"> <br><br>

      <b-button
        v-if="user[0].date_used == null"
        variant="gradient-success"
        @click="setUsed(user[0].phone, restaurant)"
      >
        CONTRASSEGNA COME UTILIZZATO
      </b-button>
    </div>
    <div
      v-if="finish && user.length == 0"
      class="text-danger"
    >
      Nessuna corrispondenza trovata.
    </div>
    <b-spinner
      v-if="loading"
      variant="primary"
      class="spinner-def"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BAlert, BButton, BSpinner,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    BAlert,
    BButton,
    BSpinner,
  },
  data() {
    return {
      user: [],
      finish: false,
      restaurant: '',
      loading: true,
    }
  },
  mounted() {
    this.checkUser()
  },
  methods: {
    getPrize(prize) {
      let x = ''
      switch (prize) {
        case 1:
          x = '10% DI SCONTO'
          break
        case 2:
          x = '15% DI SCONTO'
          break
        case 3:
          x = 'DESSERT'
          break
        case 4:
          x = 'PROSECCO'
          break
        case 5:
          x = 'BUONO 2X1'
          break
        default:
          x = 'ERRORE'
      }
      return x
    },
    isEnded(dateStart, dateEnd) {
      const x = new Date(dateStart)
      const z = new Date(dateEnd)
      const y = new Date()
      return y >= x && y <= z
    },
    getDate(date) {
      if (date !== null && date !== undefined) {
        if (date.trim() !== '') {
          return window.moment(date).format('DD/MM/YYYY')
        }
      }
      return '<b class="text-success"> NON ANCORA UTILIZZATO </b>'
    },
    setUsed(phones, restaurants) {
      this.$http.put('/scratch/users/check', { phone: phones, restaurant: restaurants }).then(() => {
        this.user = []
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Buono contrassegnato come utilizzato.',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.checkUser()
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Errore nel contrassegnare il buono.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
      })
    },
    checkUser() {
      const red = atob(decodeURIComponent(window.location.search.substr(6)))
      this.$http.post('/scratch/users/check', { phone: red }).then(response => {
        this.loading = false
        this.user = response.data
        this.finish = true
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Errore nel recupero dell\'utente.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
      })
    },
  },
}
</script>

<style>

</style>
